import React from 'react'
import Layout from "../components/Layout"
import SEO from '../components/seo'
import TermlyPolicyScript from "../components/TermlyPolicyScript";

const PrivacyPolicy = () => (
    <>
      <Layout>
        <SEO title="Privacy Statement | Academic Info" />

        <h1>
          Privacy Statement
        </h1>

        <TermlyPolicyScript privacy />
      </Layout>
    </>
);

export default PrivacyPolicy
